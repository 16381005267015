@import '../style-variables.scss';

#not-found-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @extend .medium-emphasis-font;
  h2 {
    color: $off-white;
  }
  .not-found-header {
    color: #b3b3b3;
    text-align: center;
  }
  .home-button { 
    cursor: pointer;
    border: 2px solid $off-white;
    padding: 10px;
    border-radius: 4px;
    margin-top: 50px;
    a {
      color: $off-white;
      text-decoration: none;
    }
    &:hover {
      background: $off-white;
      a {
        color: $slate-grey;
      }
    }
  }
}