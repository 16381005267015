@import '../shared/style-variables.scss';

#contact-section {
  margin-top: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .contact-container {
    margin-top: 100px;
  }
  .contact-message {
    font-size: 32px;
    text-align: center;
    color: $slate-grey;
    margin-bottom: 50px;
    @extend .medium-emphasis-font;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-top-container, .form-bottom-container {
      textarea:focus, input:focus {
        outline: none; 
        border: 2px solid $light-slate-grey;
        border-radius: 4px;
      }
    }
    .form-header {
      font-size: 20px;
      color: $grey-72;
      padding-bottom: 10px;
      @extend .medium-emphasis-font;
    }
    .form-section.form-error {
      .error-container {
        margin-top: 10px;
        color: $error-red;
        @extend .medium-emphasis-font;

      }
      label {
        @extend .medium-emphasis-font;
        color: $error-red;
      }
      input, textarea {
        border-radius: 4px;
        border: 2px solid $error-red;
      }
    }
    .form-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      input {
        padding-left: 10px;
        width: 308px;
        height: 56px;
        @extend .medium-emphasis-font;
      }
    }
    .form-top-container {
      display: flex;
      .form-section {
        &:last-child {
          padding-left: 20px;
        }
      }     
    }
    .form-bottom-container {
      padding-top: 20px;
      width: 100%;
      textarea {
        padding:10px;
        resize: vertical;
        min-height: 200px;
        @extend .medium-emphasis-font;
      }
    }
    .submit-button {
      cursor: pointer;
      background: $white;
      border-color: $light-slate-grey;
      border-radius: 4px;
      font-size: 20px;
      width: 200px;
      margin-top: 50px;
      padding: 10px;
      padding-bottom: 8px;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      @extend .medium-emphasis-font;
      &:hover {
        background: $light-slate-grey;
        color: $white;
      }
    }
  }
}

@media(max-width: 700px) {
  #contact-section {
    margin-top: 0px;
    .contact-message {
      font-size: 28px;
    }
    .contact-container {
      margin-left: 20px;
      margin-right: 20px;
    }
    form {
      .form-top-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        .form-section {
          width: 100%;
          input {
            height: 35px;
            width: 93%;
          }
        }
      }
    }
  }
}

@media(max-width: 400px) {
  #contact-section {
    margin-top: 0px;
    .contact-message {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .contact-container {
      margin-left: 20px;
      margin-right: 20px;
    }
    form {
     .form-header {
       font-size: 16px;
     }
     .form-section {
       input {
         font-size: 12px;
       }
     }
     .submit-button {
       margin-top: 20px;
     }
     .form-bottom-container {
       textarea {
         font-size: 12px;
       }
     }
    }
  }
}