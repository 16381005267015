@import '../../shared/style-variables.scss';


#skills-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
 .divider-line {
   padding-bottom: 40px;
 }
}

.skills-grid-container {
  display: flex;
  @extend .medium-emphasis-font;
}

.grid-column {
  text-align: center;
  font-size: 20px;
  color: $light-slate-grey;
  width: 275px;
  &:last-child {
    .grid-row-container {
      border-right: unset;
    }
  }

  .grid-column-header-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
    }
    .grid-column-header {
      border-bottom: 1px solid #efefef;
      font-size: 24px;
      margin-top: 30px;
      padding-bottom: 5px;
      color: $slate-grey;
      @extend .emphasis-font;
      width: max-content;
    }
  }
}
.grid-row {
  margin-top: 20px;
}

.grid-row-container {
  height: 70%;
  border-right: 1px solid #efefef;
}

@media (max-width: 995px) {
  #skills-section {
    margin-top: 0px;
    margin-bottom: 40px;
    .grid-column {
      width: 200px;
    }
  }
}


@media (max-width: 600px) {
  #skills-section {
    margin-bottom: 30px;
    .skills-grid-container {
      width: 370px;
      flex-direction: column;
      border: 1px solid #efefef;
      border-top: none;
      border-radius: 5px;
      .grid-column {
        width: auto;
        margin-bottom: 30px;
        .grid-column-header-container {
          flex-direction: row-reverse;
          border-top: 1px solid #efefef;
          margin-bottom: unset;
          .grid-column-header {
            border-bottom: unset;
          }
          img {
            width: 20px;
            height: 20px;
            padding-left: 20px;
            position: relative;
            top: 12px;
          }
        }
      }
    } 
    .divider-line {
      padding-bottom: 20px;
    }
  }
}
