@import '../../shared/style-variables.scss';

#followup-section {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.follow-up-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .paper-airplane {
    width: 60px;
    opacity: 50%;
  }
  h1 {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #262526;
    margin-top: 30px;
    margin-bottom: 30px;
    @extend .emphasis-font;
  }
  .follow-up-text {
    font-size: 20px;
    width: 700px;
    line-height: 30px;
    color: $grey-87;
    text-align: center;
    @extend .medium-emphasis-font;
  }
  .message-button-container {
    text-decoration: none;
  }
  .message-button {
    display: flex;
    margin-top: 30px;
    font-size: 20px;
    letter-spacing: 1px;
    border-radius: 4px;
    cursor: pointer;
    color: $grey-87;
    border: 1px solid $grey-87;
    padding: 10px;
    @extend .emphasis-font;
    &:hover {
      background: $grey-87;
      color: $white;
      .message-icon {
        color: $white;
        width: 20px;
        height: 20px;
        opacity: unset;
        padding-left: 10px;
      }
    }
  }
  .message-icon {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    padding-left: 10px;
  }
}

@media (max-width: 700px) {
  #followup-section {
    .follow-up-inner-container {
      .follow-up-text {
        width: unset;
        margin: 0px 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  #followup-section {
    .follow-up-inner-container {
      h1 {
        text-align: center;
        font-size: 28px;
      }
    }
  }
}