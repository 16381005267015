@import '../../shared/style-variables.scss';

#bio-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-top: 80px;
  margin-bottom: 100px;
}

.bio-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .bio-header {
    font-size: 32px;
    color: $grey-87;
    margin-top: 0px;
    @extend .medium-emphasis-font;
  }
  .bio-description {
    width: 700px;
    line-height: 1.5;
    font-size: 20px;
    color: $grey-87;
    @extend .base-font;
  }
}

.bio-pic {
  display:block;
  position:relative;
}

.divider-line {
  border-top: 1px solid $light-grey;
  width: 40px;
}

@media (max-width: 1180px) {
  .bio-text-container {
    .bio-description {
      width: 500px;
    }
  }
  .bio-pic {
    height: 570px;
  }
}

@media (max-width: 995px) {
  #bio-section {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .bio-text-container {
    .bio-description {
      width: auto;
      margin: 20px 50px;
    }
  }
  .bio-pic {
    display: none;
    height: 250px;
  }
}

@media (max-width: 600px) {
  .bio-text-container {
    .bio-header {
      font-size: 28px;
      margin-bottom: 10px;
    }
    .bio-description {
      font-size: 16px;
      margin: 10px 20px;
    }
  }
}