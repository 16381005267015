@import '../../shared/style-variables.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// Style Overwrites for Image galllery
.image-gallery-content{
  .image-gallery-slide {
    .image-gallery-image {
      width: 100%;
      height: auto;
      max-height: 400px;
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
    }
  }
}

.image-gallery-thumbnail {
  .image-gallery-thumbnail-image {
    height: 50px;
    width: 50px;
  }
} 
#projects-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 30px;
  padding-bottom: 80px;
  .grid-container {
    display: flex;
    justify-content: center;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 230px;
    width: 320px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    .inner-grid-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .upper-grid-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      .project-title {
        font-size: 20px;
        color: $white;
        @extend .emphasis-font;
      }
      .tech-stack {
        text-align: center;
        font-size: 16px;
        color: $grey-b3;
        margin-top: 10px;
        margin-bottom:10px;
        @extend .medium-emphasis-font;
      }
    }
    .project-button {
      transition: all 0.3s ease-in-out;
      border-radius: 4px;
      color: $grey-b3;
      border: 1px solid $grey-b3;
      padding: 10px;
      @extend .medium-emphasis-font;
      &:hover {
        background: $grey-b3;
        color: $slate-grey;
      }
    }
  }


  .grid-item.hovered {
    transition: background-color 0.4s ease-in-out;
    background-color: $light-slate-grey;
  }
}

.grid-image {
  width: 100%;
  object-fit: contain;
  height: 150px;
  max-width: 250px;
}
#tweet-logo {
  height: 100px;
  padding-top: 25px; // This is temp removing tweeter app soon
}

//Grid background Colors
.bg-39G {
  background: #393939;
}
.bg-34G {
  background: #343434;
}
.bg-36G {
  background: #363636;
}


@media (max-width: 1150px) {
  #projects-section {
    .grid-item {
      width: 270px;
    }
  }
}

@media (max-width: 995px) {
  #projects-section {
    padding-top: unset;
    padding-bottom: 30px;
    .grid-container {
      flex-wrap: wrap;
    }
    .grid-item {
      flex-grow: 1;
      max-width: 400px;
    }
    .grid-column-projects {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      .grid-item {
        &:last-child {
          margin-right: unset;
        }
      }     
       
    }
  }
}

@media (max-width: 600px) {
  #projects-section {
    .grid-container {
      flex-direction: column;
      width: 100%;
    }
    .grid-column-projects {
      width: auto;
      flex-direction: column;
      .grid-item {
        margin: 20px;
        margin-top: 0px;
        max-width: unset;
        width: auto;
      }
      .grid-item {
        &:last-child {
          margin-right: 20px;
        }
      }   
    }
    .section-header {
      padding-bottom: 10px;
    }
  }
}