@import '../shared/style-variables.scss';

.main-content-container {
}


.section-header {
  font-size: 32px;
  margin-bottom: 30px;
  color: $grey-87;
  @extend .medium-emphasis-font;
}

@media(max-width: 600px) { 
  .section-header {
    font-size: 28px;
    margin-bottom: 10px;
  }
}