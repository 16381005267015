//Font variables
.emphasis-font{
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
.medium-emphasis-font {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}
.light-emphasis-font {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}
.base-font {
  font-family: 'Lusitana', serif;
  font-weight: 400;
}

//Color variables
$slate-grey: #262626;
$grey-b3: #b3b3b3;
$light-slate-grey: #3f3f3f;

$off-white: #f5f5f5;
$grey-87: #878787;


$white: #fff;
$grey-72: #727272;

$light-grey: #dcdcdc; //divider line gery

$error-red: #ED4337;
