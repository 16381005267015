@import '../../shared/style-variables.scss';

.header-container {
  position: fixed;
  display: flex;
  height: 60px;
  width: 100%;
  z-index: 1;
}
.header-transparent {
  background: rgba(38,38,38,0.5)
}
.header-solid {
  background: rgb(38,38,38);
}

#header-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-left, .header-right {
  margin: 10px 15px;
}

.header-left {
  .header-logo-icon {
    width: 40px;
    height: 40px;
  }
}
.navbar-container {
  display: flex;
  :last-child {
    padding-right: unset;
  }
}
.navbar-link-container {
  padding-right: 20px;
  color: $off-white;
  font-size: 16px;
  .navbar-link {
    color: $off-white;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid $off-white;
    }
  }
  @extend .medium-emphasis-font
}
.mobile-nav-container {
  display: none;
}
.web-nav-container {
  display: flex
}

@media (max-width: 600px) {
  .mobile-nav-container {
    display: flex;
    .dropdown-container {
      width: 100%;
      height: max-content;
      position: absolute;
      top: 60px;
      right: 0px;
      background-color: $light-slate-grey;
      .navbar-link-container {
        margin: 20px;
        font-size: 18px;
      }
    }
    img {
      width: 30px;
    }
  }
  .web-nav-container {
    display: none;
  }
}